<template>
  <div class="d-flex registration-container flex-row justify-content-evenly flex-wrap ">
    <div class="d-flex flex-column justify-content-evenly col-12 col-md-6 col-lg-6 col-xl-4">
      <div class="d-flex flex-column welcome-message p-4">
        <div style="font-size: 20px">Welcome to the</div>
        <div style="font-size: 45px; font-weight: bold" >FANCLASH</div>
        <div style="font-size: 45px; font-weight: bold">INTERNAL</div>
        <div style="font-size: 45px; font-weight: bold">DASHBOARD</div>
      </div>
      <div class="d-flex flex-column p-4">
        <img class="illustration-image" src="../assets/Illustration-13.png" style="width: 350px">
      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center text-center col-12 col-md-6 col-lg-6 col-xl-4 pb-5">
      <p class="pt-5" style="font-size: 20px">Please add your details to get started</p>
      <div class="d-flex flex-row flex-wrap justify-content-center">
        <div class="d-flex flex-column">
          <input v-model="name" type="text" class="m-2" placeholder="name" required>
          <input v-model="surname" type="text" class="m-2" placeholder="surname" required>
          <input v-model="msisdn" v-on:change="msisdnConversion" type="text" class="m-2" placeholder="cell number" required>
          <input v-model="email" id="emailAddress" type="email" class="m-2" required placeholder="email" pattern=".+@thefanclash\.com" title="Please provide only a Fanclash corporate e-mail address">
          <label for="birth">Date of Birth</label>
          <input v-model="birthDate" type="date" id="birth" class="m-2" required>
          <input v-model="teamSoccer" type="text" class="m-2" placeholder="favorite football team" required>
          <label for="kinName">Next of Kin</label>
          <input v-model="kinName" id="kinName" type="text" class="m-2" placeholder="name & surname" required>
          <input v-model="kinNumber" type="text" class="m-2" placeholder="cell number" required>
        </div>
        <div class="d-flex flex-column">
          <label for="password">Create your password</label>
          <input v-model="password" v-on:change="validatePasswordStrength" type="password" id="password" class="m-2" placeholder="password" required>
          <input v-model="confirmPassword" v-on:change="validateConfirmPassword" type="password" class="m-2" name="password" placeholder="confirm password" required>
          <div v-if="passwordTooWeak">
            <p style="color:red ;white-space: pre-wrap">{{ passwordWeakMessage }}</p>
          </div>
          <div class="d-flex text-center" v-if="passwordsDontMatch">
            <p style="color:red ;white-space: pre-wrap">{{ passwordsDontMatchMessage }}</p>
          </div>
          <label for="registrationKey">Fanclash Registration Key</label>
          <input v-model="registrationKey" v-on:change="validateRegistrationKeyLength" type="text" class="m-2" id="registrationKey" placeholder="unique key" required>
          <div class="d-flex text-center" v-if="registrationKeyLengthIncorrect">
            <p style="color:red ;white-space: pre-wrap">{{ registrationKeyIncorrectLengthMessage }}</p>
          </div>
          <div class="d-flex text-center" v-if="registrationKeyIncorrect">
            <p style="color:red ;white-space: pre-wrap">{{ registrationKeyIncorrectMessage }}</p>
          </div>
          <div class="d-flex flex-column profile-pic-div align-items-center pt-1">
            <label for="file" id="uploadBtn">Add Profile Picture</label><br>
            <img src="../assets/male-profile.png" style="width: 100px" id="profile-img" class="pb-1">
            <input type="file" id="file" accept="" @change="handleImageUploadToRegistrationPage($event)" style="font-size: 10px; padding-left: 80px; padding-top: 8px">
          </div>
          <div class="d-flex flex-row justify-content-center pt-3">
            <button @click="handleSubmit" class="create-profile-btn mt-2">create fanclash profile</button>
          </div>
          <div class="d-flex flex-row justify-content-center back-button-div pt-5">
            <button @click="onBackButtonClicked" >go back</button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center text-center col-12 col-md-6 col-lg-6 col-xl-4">
      <div class="d-flex logo-container flex-row justify-content-evenly">
        <div class="p-2"><img src="../assets/Penrose-Gaming-Logo-White-01.png" class="fanclash-logo-img" ></div>
        <div class=" digital-penrose-logo p-2 m-1"><img src="../assets/Penrose-Digital-Logo-White-01.png" class="fanclash-logo-img" ></div>
      </div>
      <div class="pt-3 pb-5" style="font-size: 25px">Your home of competition and rewards</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import bcrypt from 'bcryptjs';
import passwordValidator from 'password-validator';
import { BlobServiceClient } from '@azure/storage-blob';
import UserAuthenticationService from '../services/userAuthenticationService';

export default {
  name: 'RegisterPage',
  data() {
    return {
      /* eslint-disable */
      selectedFile: null,
      name: '',
      surname: '',
      msisdn: '',
      email: '',
      birthDate: '',
      teamSoccer: '',
      kinName: '',
      kinNumber: '',
      password: '',
      confirmPassword: '',
      hashedPassword: '',
      registrationKey: '',
      profileImg: '',
      passwordTooWeak: undefined,
      passwordWeakMessage: 'Password requirements:\n-Minimum length of 8 characters\n-One uppercase letter\n-One lowercase letter\n-One symbol\n-One number\n-No spaces',
      passwordsDontMatch: false,
      passwordsDontMatchMessage: `Passwords don't match. Please try again`,
      registrationKeyLengthIncorrect: false,
      registrationKeyIncorrectLengthMessage: 'Registration key length is incorrect. Please try again',
      registrationKeyIncorrect: false,
      registrationKeyIncorrectMessage: 'Registration key is incorrect. please try again',
      existingUser: false,
      existingUserMessage: 'A user with this email & number already exists. Please proceed to the login page',
    };
  },
  computed: {
    ...mapState(['userData']),
  },
  methods: {
    ...mapMutations(['setUser']),
    async handleSubmit() {
      await this.registerUser();
      await this.uploadImageBlobToAzure();
      this.validateRegistrationKey();
      this.checkUserExists();
      this.routeToDashboard();

    },
    msisdnConversion() {
      if (this.msisdn.startsWith('0')) {
        this.msisdn = this.msisdn.slice(1);
        const saNumber = '27';
        this.msisdn = parseInt(saNumber + this.msisdn, 10);
      } else if (this.msisdn.startsWith('+')) {
        this.msisdn = this.msisdn.slice(1);
      }
    },
    validatePasswordStrength() {
      /* eslint-disable new-cap */
      const schema = new passwordValidator();
      schema.is().min(8);
      schema.has().uppercase();
      schema.has().lowercase();
      schema.has().digits(1);
      schema.has().not().spaces();
      schema.has().symbols();
      const passwordCheck = schema.validate(this.password);
      this.passwordTooWeak = passwordCheck === false;
    },
    validateConfirmPassword() {
      this.passwordsDontMatch = this.password !== this.confirmPassword;
    },
    handleImageUploadToRegistrationPage(event) {
     this.file = event.target.files[0];
      const output = document.getElementById('profile-img');
      this.profileImg = output;
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src);
      };
    },
    async uploadImageBlobToAzure() {
      const account = process.env.VUE_APP_AZURE_ACCOUNT;
      const sas = process.env.VUE_APP_SAS;
      const blobServiceClient = new BlobServiceClient(
          `https://${account}.blob.core.windows.net${sas}`,
      );
      const containerName = process.env.VUE_APP_CONTAINER_NAME;
      const content = this.name + '_' + this.surname;
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blockBlobClient = containerClient.getBlockBlobClient(content);
      const options = { blobHTTPHeaders: { blobContentType: this.file.type } };
      await blockBlobClient.uploadData(this.file,options);
    },
    onBackButtonClicked() {
      this.$router.push('/');
    },
    async registerUser() {
      const userDetailsResponse = await UserAuthenticationService.register({
        name: this.name,
        surname: this.surname,
        msisdn: this.msisdn,
        email: this.email,
        birthDate: this.birthDate,
        teamSoccer: this.teamSoccer,
        kinName: this.kinName,
        kinNumber: this.kinNumber,
        hashedPassword: bcrypt.hashSync(this.password, 10),
        registrationKey: this.registrationKey,
      });
      console.log(userDetailsResponse.data);
      this.setUser(userDetailsResponse.data.UserData);
      this.registrationKeyValid = userDetailsResponse.data.Key;
    },
    routeToDashboard() {
      if (this.registrationKeyValid === true && this.userData !== false) {
        this.$router.push({ path: '/dashboard' });
      }
    },
    validateRegistrationKey() {
      if (this.registrationKeyValid === false){
        this.registrationKeyIncorrect = true;
      }
    },
    validateRegistrationKeyLength() {
      this.registrationKeyLengthIncorrect = this.registrationKey.length !== 36;
    },
    checkUserExists() {
      if (this.userData === false) {
        this.existingUser = true;
      }
    },
    }
};
</script>

<style scoped>
.registration-container{
  background-image: url("../assets/Background.png");
  background-color: white ;
  background-size: cover ;
  background-repeat: no-repeat;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
}

input {
  width: 300px;
  height: 45px;
  border-radius: 20px;
  border: 2px solid #F1F1F1;
  padding-left: 15px;
  background-color: #eeeeee;
  font-size: 20px;
  color: black;
}

.create-profile-btn {
  border-radius: 20px;
  color: white;
  background-color: black;
  width: 300px;
  height: 50px;
  font-size: 22px;
}

#profile-img {
  position: relative;
  bottom: 5%;
  width: 8vw;
}

.profile-pic-div {
  position: relative;
}

.fanclash-logo-img {
  width: 100px;
}

.back-button-div button {
  border: 2px solid black;
  border-radius: 20px;
  color: black;
  width: 150px;
}

@media screen and (max-width: 790px) {
  .illustration-image {
    display: none;
  }
}
</style>
