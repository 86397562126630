<template>
  <div class="approve-leave-container">
    <LoadingScreen v-if="loadingScreen"></LoadingScreen>
    <div class="d-flex flex-row flex-wrap">
      <div class="d-flex align-items-center flex-column bd-highlight left-div mb-3 col-xl-4 col-lg-4 col-md-3" style="height: auto;">
        <div class="p-4 bd-highlight">
          <div style="font-size: 20px">Welcome to the</div>
          <div style="font-size: 30px; font-weight: bold">EMPLOYEE LEAVE</div>
          <div style="font-size: 30px; font-weight: bold">CALENDAR</div>
        </div>
        <div class="mt-auto d-flex logo-container flex-column p-4 bd-highlight">
          <div class="d-flex flex-row justify-content-around">
            <div class="p-4"><img src="../assets/Penrose-Gaming-Logo-White-01.png" class="fanclash-logo-img" style="width: 100px"></div>
            <div class=" digital-penrose-logo p-4 m-1"><img src="../assets/Penrose-Digital-Logo-White-01.png" class="fanclash-logo-img" style="width: 100px"></div>
          </div>
        </div>
      </div>
      <div class="d-flex right-div align-items-start flex-column bd-highlight mb-3 col-xl-8 col-lg-8 col-md-9 align-items-center">
        <div class="p-4"><h4>Staff Leave Calendar</h4></div>
        <DatePicker :columns="$screens({ default: 1, lg: 2 })" show-weeknumbers="left-outside" :attributes="datesInCalendar" :min-date="new Date()" is-range style="height: 240px;"/>
        <div class="row col-xl-12 justify-content-center leave-table pt-5">
          <table class="table table-striped table-responsive">
            <thead class="text-center">
            <tr>
              <th v-if="showIDColumnInTable" scope="col">ID</th>
              <th scope="col">Surname</th>
              <th>Leave Days Owed</th>
              <th v-if="showMsisdnColumnInTable" scope="col">Number</th>
              <th scope="col">Type</th>
              <th scope="col">Start</th>
              <th scope="col">End</th>
              <th scope="col">Duration</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="employee in leaveTable" :key="employee.id">
              <td v-if="showIDColumnInTable">{{ employee.leaveId }}</td>
              <td>{{ employee.surname }}</td>
              <td>{{ employee.leaveDaysAvailable }}</td>
              <td v-if="showMsisdnColumnInTable">0{{ employee.msisdn.substring(2) }}</td>
              <td>{{ employee.typeLeave }}</td>
              <td>{{ employee.startDate.split('-').reverse().join('-') }}</td>
              <td>{{ employee.endDate.split('-').reverse().join('-') }}</td>
              <td style="font-weight: bold">{{ employee.durationLeave + ' Day(s)' }}</td>
              <td>{{ employee.statusLeave }}</td>
              <td>
                <button class="btn-approve" v-if="employee.statusLeave === 'Pending'" @click="approveBtnClicked(employee)">✓</button>
                <button class="btn-deny" v-if="employee.statusLeave === 'Pending'" @click="denyBtnClicked(employee)">✕</button>
                <button @click="changeBtnClicked(employee)" class="btn-change" v-if="employee.statusLeave === 'Approved'">change</button>
                <button @click="changeBtnClicked(employee)" class="btn-change" v-if="employee.statusLeave === 'Denied'">change</button>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="d-flex flex-row justify-content-center back-button-div pt-3">
            <button @click="backButtonClicked">Go back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { DatePicker } from 'v-calendar';
import LoadingScreen from './LoadingScreen.vue';
import LeaveRequestService from '../services/leaveRequestService';

export default {
  name: 'ApproveLeave',
  components: { DatePicker, LoadingScreen },
  computed: {
    ...mapState(['allEmployeesRequestedLeave']),
  },
  beforeMount() {
    /* eslint-disable */
    this.employeeRequestLeaveController();
    this.hideLoadingIcon();
  },
  data() {
    return {
      loadingScreen: true,
      leaveTable: [],
      datesInCalendar: [],
      showIDColumnInTable: false,
      showMsisdnColumnInTable: false,
    };
  },
  methods: {
    hideLoadingIcon() {
      if (this.leaveTable !== undefined) {
        this.loadingScreen = false;
      }
    },
    logoutBtnClicked() {
      this.$router.push('/');
    },
    employeeRequestLeaveController() {
      this.allEmployeesRequestedLeave.forEach((leaveItem) => {
        let durationLeaveHourToDayConversion = leaveItem.DurationHours * 3 / 24;
        let startDateToString = leaveItem.StartDate.toString().slice(0, 10);
        let endDateToString = leaveItem.EndDate.toString().slice(0, 10);
        let reversedStartDateSplit = startDateToString.split('-');
        let startDate = reversedStartDateSplit[2];
        let startMonth = reversedStartDateSplit[1] - 1;
        let startYear = reversedStartDateSplit[0];
        let startDateCalendar = new Date(startYear, startMonth, startDate);
        if (leaveItem.Approved === null) {
          this.statusLeaveApplication = 'Pending';
          this.leaveCalenderHighlight = 'blue';
        } else if (leaveItem.Approved === true) {
          this.statusLeaveApplication = 'Approved';
          this.leaveCalenderHighlight = 'green';
        } else {
          this.statusLeaveApplication = 'Denied';
          this.leaveCalenderHighlight = 'red';
        }
        this.inputLeaveRequestsToTable(leaveItem, startDateToString, endDateToString, durationLeaveHourToDayConversion);
        this.sortLeaveTableByMostRecentDate();
        this.pushDatesToDatePickerCalendar(startDateCalendar, durationLeaveHourToDayConversion, leaveItem);
      })
    },
    inputLeaveRequestsToTable(leaveItem, startDateToString, endDateToString, durationLeaveHourToDayConversion) {
      this.leaveTable.push({
        leaveId: leaveItem.LeaveId,
        surname: leaveItem.Surname,
        leaveDaysAvailable: leaveItem.LeaveDays,
        msisdn: leaveItem.Msisdn,
        typeLeave: leaveItem.LeaveType,
        startDate: startDateToString,
        endDate: endDateToString,
        durationLeave: durationLeaveHourToDayConversion,
        statusLeave: this.statusLeaveApplication
      })
    },
    sortLeaveTableByMostRecentDate() {
      this.leaveTable.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    },
    pushDatesToDatePickerCalendar(startDateCalendar, durationLeaveHourToDayConversion, leaveItem) {
      this.datesInCalendar.push({
        highlight: this.leaveCalenderHighlight,
        dates: {start: startDateCalendar, span: durationLeaveHourToDayConversion},
        popover:
            {label: leaveItem.Surname + ' ' + leaveItem.LeaveType + ' Leave [' + this.statusLeaveApplication + ']'}
      })
    },
    async approveBtnClicked(employee) {
      await this.sendApprovedLeaveSMS(employee);
      await this.approveLeaveRequestInDBTable(employee);
      employee.statusLeave = 'Approved';
    },
    async sendApprovedLeaveSMS(employee) {
      await LeaveRequestService.sendSMS({
        msisdn: employee.msisdn,
        network: "Vodacom",
        text: `Hi ${employee.surname}!  ${employee.typeLeave} leave approved by Admin. From the ${employee.startDate} to the ${employee.endDate}. ${employee.durationLeave} day(s)`
      })
    },
    async approveLeaveRequestInDBTable(employee) {
      await LeaveRequestService.approveDenyLeaveRequest({
        leaveId: employee.leaveId,
        approved: 1,
      })
    },
    changeBtnClicked(employee) {
      employee.statusLeave = 'Pending';
    },
    async denyBtnClicked(employee) {
      await this.sendDeniedLeaveSMS(employee);
      await this.denyLeaveRequestInDBTable(employee);
      employee.statusLeave = 'Denied';
    },
    async sendDeniedLeaveSMS(employee) {
      await LeaveRequestService.sendSMS({
        msisdn: employee.msisdn,
        network: "Vodacom",
        text: `Hi ${employee.surname}!  ${employee.typeLeave} leave denied by Admin. From the ${employee.startDate} to the ${employee.endDate}. ${employee.durationLeave} day(s)`
      })
    },
    async denyLeaveRequestInDBTable(employee) {
      await LeaveRequestService.approveDenyLeaveRequest({
        leaveId: employee.leaveId,
        approved: 0,
      })
    },
    backButtonClicked() {
      this.$router.push('/dashboard');
    },
  },
};
</script>

<style scoped>

.logout-button {
  width: 80px;
  border-radius: 20px;
  border: 2px solid black;
  color: white;
  background-color: #2c84e8;
}

.approve-leave-container {
  background-image: url("../assets/Background.png");
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  min-height: 100vh;
}


.back-button-div button {
  border: 2px solid black;
  color: black;
  border-radius: 20px;
  background-color: white;
  width: 100px;
}

.approve-deny-button-div button {
  width: 100px;
}

.btn-approve {
  border: 2px solid black;
  border-radius: 20px;
  color: black;
  background-color: #06c506;
  width: 50px;
}

.btn-deny {
  border: 2px solid black;
  border-radius: 20px;
  color: black;
  background-color: red;
  width: 50px;
}

.btn-change {
  border: 2px solid black;
  border-radius: 20px;
  color: black;
  background-color: white;
  width: 80px;
}

table thead {
  font-size: 10px;
  font-weight: lighter;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

table tbody {
  font-size: 14px;;
  text-align: center;
}

table button {
  margin: 1%;
}

@media screen and (max-width: 992px) {
  .fanclash-logo-img {
    display: none;
  }
}

@media (max-width: 700px) {
  table {
    width: 90vw;
    font-size: 15px;
  }

  table button {
    width: 50px;
  }
}

@media (max-width: 620px) {
  table {
    width: 90vw;
    font-size: 15px;
    display: block;
    overflow: auto;
    white-space: nowrap;
  }
}
</style>
