<template>
  <div id="nav">
    <NavBar />
  </div>
  <router-view />
</template>

<style>
</style>
<script>
import NavBar from './pages/NavBar.vue';

export default {
  components: { NavBar },

};
</script>
