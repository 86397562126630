<template>
  <div class="leaderboard-container">
    <LoadingScreen v-if="loadingScreen"></LoadingScreen>
    <div class="d-flex flex-wrap justify-content-between flex-row pt-4">
        <div class="d-flex flex-column col-12 col-md-4 ">
          <div class="d-flex flex-column welcome-message p-4">
            <div style="font-size: 20px">Welcome to the</div>
            <div style="font-size: 30px; font-weight: bold" >FANCLASH</div>
            <div style="font-size: 30px; font-weight: bold">INTERNAL</div>
            <div style="font-size: 30px; font-weight: bold">LEADERBOARD</div>
          </div>
          <div class="d-flex flex-column pt-5 justify-content-end p-4">
              <img class="illustration-image" src="../assets/Illustration-11.png" style="width: 320px">
          </div>
        </div>
        <div class="d-flex flex-column col-12 col-md-4 justify-content-start text-center">
          <h5>Internal Fanclash Dashboard</h5>
          <div class="align-content-center" style="width: 90%; margin: 5%">
            <table class="table table-borderless table-responsive">
              <thead class="text-center">
              <tr>
                <th scope="col" style="font-weight: normal">Position</th>
                <th scope="col" style="font-weight: normal">Username</th>
                <th scope="col" style="font-weight: normal">Points</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(user) in leaderboard" :key="user.id">
                <td>{{ leaderboard.indexOf(user) + 1 }}</td>
                <td>{{ user.Username}}</td>
                <td>{{ user.Points}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex flex-row justify-content-center pt-3">
            <button @click="howItWorksBtnClicked" class="how-it-works-btn m-1">How it works</button>
            <p class="m-2" style="font-size: 14px">Promotion ends: 31-03-2022</p>
          </div>
          <div v-if="showHowItWorksPopUp" class="pt-3">
            <p style="color: #0d85de">The Vodacom Fantasy Internal Promotion pertains only to Fantasy Football. No Predict & Win Points will be allocated to the overall leaderboard.</p>
          </div>
          <div class="d-flex flex-row justify-content-center back-button-div pt-4">
            <button class="m-1" @click="backButtonClicked">go back</button>
          </div>
        </div>
        <div class="d-flex flex-column  col-12 col-md-4 pt-5 align-items-end justify-content-end">
          <div class="d-flex flex-column justify-content-center text-center">
            <div class="d-flex logo-container flex-row justify-content-evenly">
              <div class="p-2"><img src="../assets/Penrose-Gaming-Logo-White-01.png" class="fanclash-logo-img" ></div>
              <div class=" digital-penrose-logo p-2 m-1"><img src="../assets/Penrose-Digital-Logo-White-01.png" class="fanclash-logo-img" ></div>
            </div>
            <div class="pt-3 pb-5" style="font-size: 25px">Your home of competition and rewards</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoadingScreen from './LoadingScreen.vue';
import LeaderboardService from '../services/leaderboardService';

export default {
  name: 'LeaderBoard',
  components: { LoadingScreen },
  data() {
    return {
      loadingScreen: true,
      leaderboard: [],
      internalLeaderboardUsersPoints: undefined,
      showHowItWorksPopUp: null,
    };
  },
  computed: {
    ...mapState(['allInternalUsersMsisdnsAndTokens']),
  },
   async beforeMount() {
     this.internalLeaderboardUsersPoints = await LeaderboardService.getFantasyPoints(this.allInternalUsersMsisdnsAndTokens);
     this.sortFantasyLeaderboardStandings();
     this.loadingScreenDisable();
    /* eslint-disable */
  },
  methods: {
    loadingScreenDisable(){
      if (this.leaderboard !== undefined) {
        this.loadingScreen = false;
      }
    },
    logoutBtnClicked() {
      this.$router.push('/');
    },
    sortFantasyLeaderboardStandings() {
      this.leaderboard = this.internalLeaderboardUsersPoints.data;
      this.leaderboard.sort(function (a, b) {
        return b.Points - a.Points;
      })
    },
    howItWorksBtnClicked(){
      this.showHowItWorksPopUp =! this.showHowItWorksPopUp;
    },
    backButtonClicked() {
      this.$router.push('/dashboard');
    },
  }
};
</script>

<style scoped>

.leaderboard-container {
  background-image: url("../assets/Background.png");
  background-color: white ;
  background-size: cover ;
  background-repeat: no-repeat;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  min-height: 100vh;
}

.fanclash-logo-img {
  width: 80px;
}

table {
  background-image: url("../assets/Background.png");
  background-color: white ;
  background-size: cover ;
  background-repeat: no-repeat;
}

table thead {
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

table tbody {
  font-size: 16px;;
  text-align: center;
}

.back-button-div button {
  border: 2px solid black;
  border-radius: 20px;
  color: black;
  width: 150px;
}

.how-it-works-btn {
  width: 150px;
  height: 30px;
  border-radius: 20px;
  border: 2px solid black;
  color: black;
  background-color: white;
}

@media screen and (max-width: 790px) {
  .illustration-image {
    display: none;
  }
}
</style>
