import { createApp } from 'vue';
import { SetupCalendar } from 'v-calendar';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/src/jquery';
import 'bootstrap/dist/js/bootstrap.min';
import 'v-calendar/dist/style.css';

createApp(App).use(router).use(SetupCalendar).use(store)
    .mount('#app');
