<template>
  <div id="loading">
    <img
        class="img"
        src="../assets/loading-screen.gif"
        alt="loading..."
        width="150"
    />
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
};
</script>

<style scoped>

#loading {
  position: absolute;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  text-align: center;
  line-height: 100vh;
}
.img {
  position: relative;
  margin: auto;
}

</style>
