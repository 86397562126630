<template>
  <div class="view-leave-status-container">
    <LoadingScreen v-if="loadingScreenIcon"></LoadingScreen>
    <div class="d-flex flex-row flex-wrap">
      <div class="d-flex flex-column col-12 col-md-4 ">
        <div class="d-flex flex-column welcome-message p-4">
          <div style="font-size: 20px">Welcome to your</div>
          <div style="font-size: 30px; font-weight: bold" >LEAVE TABLE</div>
        </div>
        <div class="d-flex flex-row p-4 pb-5">
          <img :src="profileImage" class="user-profile-image">
          <div class="greetings-container">
            <h4 class="">Hello {{ userData.Name }}</h4>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-end pt-4 p-4">
          <img class="illustration-image" src="../assets/Illustration-12.png" style="width: 320px">
        </div>
      </div>
      <div class="d-flex table-container text-center col-8 flex-column p-4">
        <h5>Leave Application Status</h5>
        <div class="" style="margin: 5%">
          <table class="table table-borderless table-responsive">
            <thead class="col-auto">
            <tr>
              <th v-if="showLeaveIdColumn" scope="col">ID</th>
              <th scope="col" style="font-weight: normal">Type</th>
              <th scope="col" style="font-weight: normal">Start Date</th>
              <th scope="col" style="font-weight: normal">End Date</th>
              <th scope="col" style="font-weight: normal">Duration</th>
              <th scope="col" style="font-weight: normal">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="user in leaveTable" :key="user.key">
              <td v-if="showLeaveIdColumn">{{user.leaveId}}</td>
              <td>{{user.typeLeave}}</td>
              <td>{{user.startDate.split('-').reverse().join('-')}}</td>
              <td>{{user.endDate.split('-').reverse().join('-')}}</td>
              <td>{{user.durationLeave + ' day(s)'}}</td>
              <td :class="getStatusFontColour">{{user.statusLeave}}</td>
            </tr>
            </tbody>
          </table>
          <div class="d-flex pt-2 flex-column text-center leave-days-available-div">
            Remaining Leave: <div class="days-remaining-text"> {{userData.LeaveDays + ' day(s)'}}</div>
          </div>
          <div class="d-flex back-button-div justify-content-center pt-3">
            <button  @click="backButtonClicked">Go back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoadingScreen from './LoadingScreen.vue';

export default {
  name: 'UserLeaveApplicationStatus',
  components: { LoadingScreen },

  data() {
    return {
      loadingScreenIcon: true,
      leaveTable: [],
      userLeaveTable: [],
      showLeaveIdColumn: false,
      profileImage: undefined,
    };
  },
  computed: {
    ...mapState(['userData', 'allEmployeesRequestedLeave']),
  },
  beforeMount() {
    this.findUserLeave();
    this.disableLoadingScreenIcon();
    this.profileImage = `https://${process.env.VUE_APP_AZURE_ACCOUNT}.blob.core.windows.net/${process.env.VUE_APP_CONTAINER_NAME}/${this.userData.Name}_${this.userData.Surname}`;
  },
  methods: {
    disableLoadingScreenIcon() {
      if (this.leaveTable !== undefined) {
        this.loadingScreenIcon = false;
      }
    },
    logoutBtnClicked() {
      this.$router.push('/');
    },
    findUserLeave() {
      /* eslint-disable */
      this.userLeaveTable = this.allEmployeesRequestedLeave.filter(({ Surname }) => Surname === this.userData.Surname);
      this.userLeaveTable.forEach((leaveItem) => {
        let durationLeaveHourToDayConversion = leaveItem.DurationHours * 3 / 24;
        let startDateToString = leaveItem.StartDate.toString().slice(0,10);
        let endDateToString = leaveItem.EndDate.toString().slice(0,10);
        if (leaveItem.Approved === null) {
          this.statusLeaveApplication = 'Pending'
        } else if (leaveItem.Approved === true) {
        this.statusLeaveApplication = 'Approved'
      } else {
        this.statusLeaveApplication = 'Denied'
      }
        this.leaveTable.push({
          leaveId: leaveItem.LeaveId,
          availableLeaveDays: '',
          typeLeave: leaveItem.LeaveType,
          startDate: startDateToString,
          endDate: endDateToString,
          durationLeave: durationLeaveHourToDayConversion,
          statusLeave : this.statusLeaveApplication
        })
      });
    },
    backButtonClicked() {
      this.$router.push('/dashboard');
    },
  },
};
</script>

<style scoped>

.greetings-container {
  background-color: black;
  color: white;
  font-size: 16px;
  width: 250px;
  border-radius: 20px;
  height: 40px;
  padding-left: 12px;
  padding-top: 9px;
}
.user-profile-image {
  width:50px;
  height:50px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -5px;
}

.view-leave-status-container {
  font-family: 'Montserrat', sans-serif;
  background: url("../assets/Background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

table {
  background-image: url("../assets/Background.png");
  background-color: white ;
  background-size: cover ;
  background-repeat: no-repeat;
}

table thead {
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

table tbody {
  font-size: 16px;;
  text-align: center;
}

.back-button-div button {
  border: 2px solid blue;
  border-radius: 6px;
  color: blue;
  width: 100px;
}

.leave-days-available-div {
  font-size: 15px;
}

.days-remaining-text {
  color: #000000;
  font-weight: bold;
}

.back-button-div button {
  border: 2px solid black;
  background-color: white;
  border-radius: 20px;
  color: black;
  width: 130px;
}

@media screen and (max-width: 860px) {
  .illustration-image {
    display: none;
  }
}

@media (max-width: 620px) {
  .table-container {
    width: 375px;
  }
  table {
    font-size: 15px;
    display: block;
    overflow: auto;
    white-space: nowrap;
  }
}
</style>
