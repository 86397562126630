<template>
<div class="d-flex flex-column align-items-center forgot-password-container">
  <div class="mt-5 p-2"><img src="../assets/lock.png" class="lock-img" ></div>
  <div class="pt-2 text-center"><h1>Forgot Password?</h1></div>
  <form class="d-flex pt-2 flex-column forgot-password-form" @submit.prevent="onChangePasswordClicked">
    <label for="email">Email address</label>
    <input v-model="email" id="email" type="email">
    <label for="password">New Password</label>
    <input v-model="password" id="password" type="password" v-on:change="validatePasswordStrength">
    <div class="d-flex flex-column" v-if="passwordTooWeak">
      <p style="color:red ;white-space: pre-wrap">{{ passwordWeakMessage }}</p>
    </div>
    <label for="confirmPassword">Confirm Password</label>
    <input v-model="confirmPassword" id="confirmPassword" v-on:change="validateConfirmPassword" type="password">
    <div class="d-flex flex-column text-center" v-if="passwordsDontMatch">
      <p style="color:red ;white-space: pre-wrap">{{ passwordsDontMatchMessage }}</p>
    </div>
    <label for="registrationKey">Unique Key</label>
    <input v-model="registrationKey" id="registrationKey" v-on:change="validateUniqueKeyLength">
    <button class="d-flex change-password-button mt-2" type="submit">Change Password</button>
  </form>
  <div class="d-flex flex-column text-center pt-4" v-if="registrationKeyIncorrect">
    <p style="color:red ;white-space: pre-wrap">{{ registrationKeyIncorrectMessage }}</p>
  </div>
  <div class="d-flex flex-column text-center pt-4" v-if="registrationKeyLengthIncorrect">
    <p style="color:red ;white-space: pre-wrap">{{ registrationKeyLengthIncorrectMessage }}</p>
  </div>
  <div class="d-flex flex-row justify-content-center back-button-div pt-5">
    <button @click="onBackButtonClicked" >GO BACK</button>
  </div>
  <div class="d-flex flex-column successful-password-change-popup text-center align-items-center" v-if="showPasswordSuccessfullyChangedPopUp">
    <h4 class="d-flex pt-2">Password Succesfully Changed</h4>
    <p>Please proceed to the Login page by clicking the button below</p>
    <button @click="onLoginButtonClicked">LOGIN</button>
  </div>
</div>
</template>

<script>
import bcrypt from 'bcryptjs';
import passwordValidator from 'password-validator';
import UserAuthenticationService from '../services/userAuthenticationService';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      /* eslint-disable */
      email: undefined,
      password: undefined,
      confirmPassword: undefined,
      hashedPassword: undefined,
      registrationKey: undefined,
      passwordTooWeak: false,
      passwordWeakMessage: 'Password requirements:\n-Minimum length of 8 characters\n-One uppercase letter\n-One lowercase letter\n-One symbol\n-One number\n-No spaces',
      passwordsDontMatch: false,
      passwordsDontMatchMessage: `Passwords don't match. Please try again`,
      registrationKeyValid: undefined,
      registrationKeyIncorrect: undefined,
      registrationKeyIncorrectMessage: 'Unique Key is incorrect. Please try again',
      registrationKeyLengthIncorrect: false,
      registrationKeyLengthIncorrectMessage: 'UUID character length is incorrect. Please try again',
      showPasswordSuccessfullyChangedPopUp: false,
    };
  },
  methods: {
    async onChangePasswordClicked() {
      await this.registerUserNewPassword();
      this.validateUniqueKey();
      await this.passwordSuccessfullyChangedPopUp();
    },
    async registerUserNewPassword() {
      const forgotPasswordResponse = await UserAuthenticationService.forgotPassword({
        email: this.email,
        hashedPassword: bcrypt.hashSync(this.password, 10),
        registrationKey: this.registrationKey,
      });
      this.registrationKeyValid = forgotPasswordResponse.data.Key;
    },
    validatePasswordStrength() {
      /* eslint-disable new-cap */
      const schema = new passwordValidator();
      schema.is().min(8);
      schema.has().uppercase();
      schema.has().lowercase();
      schema.has().digits(1);
      schema.has().not().spaces();
      schema.has().symbols();
      const passwordCheck = schema.validate(this.password);
      this.passwordTooWeak = passwordCheck === false;
    },
    validateConfirmPassword() {
      this.passwordsDontMatch = this.password !== this.confirmPassword;
    },
    validateUniqueKey() {
      this.registrationKeyIncorrect = this.registrationKeyValid === false;
    },
    validateUniqueKeyLength() {
      this.registrationKeyLengthIncorrect = this.registrationKey.length !== 36;
    },
    async passwordSuccessfullyChangedPopUp() {
      if(this.registrationKeyValid === true) {
        this.showPasswordSuccessfullyChangedPopUp = true
      }
    },
    onBackButtonClicked() {
      this.$router.push('/');
    },
    onLoginButtonClicked() {
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
.forgot-password-container {
  /*position: relative;*/
  background-image: url("../assets/Background.png");
  background-color: white ;
  font-family: 'Montserrat', sans-serif;
  background-repeat: no-repeat;
  background-size: auto 100%;
  min-height: 100vh;
}
.lock-img{
  width: 120px;
}

label {
  font-size: 20px;
}
input{
  font-size: 20px;
  width: 300px;
  border-radius: 6px;
  border: 2px solid #E2E2E2;
  padding-left: 5px;
  margin-bottom: 8px;
}

.change-password-button{
  border-radius: 6px;
  border: 2px solid black;
  color: black;
  background-color: #F9F8F8;
  justify-content: center;
}

.back-button-div button {
  border: 2px solid black;
  border-radius: 6px;
  color: black;
  width: 150px;
  text-align: center;
}

button{
  width: 300px;
  font-size: 20px;
  height: 40px;
}

.successful-password-change-popup{
  position: absolute;
  border-radius: 6px;
  border: 2px solid black;
  width: 40vw;
  height: 140px;
  top: 35%;
  left: 30%;
  z-index: 1;
  background-color: #F1F1F1;
}
</style>
