import axios from 'axios';
import { environmentConfig } from '../config/environmentConfig';

export default class LeaveRequestService {
    /* eslint-disable */
    static async applyLeave(credentials) {
        return await axios.post(`${environmentConfig.InternalDashboardHost}/request-leave`, credentials);
    }

    static async sendSMS(credentials) {
        let header = {endpoint: `${environmentConfig.smsIntegrationService}/sms/sendSms`}
        try {
            return await axios.post(`${environmentConfig.smsIntegrationService}/utils/proxy`, credentials, {headers: header}
            );
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    }

    static async employeeRequestedLeave() {
        return await axios.get(`${environmentConfig.InternalDashboardHost}/get-all-employee-requested-leave`);
    }

    static async approveDenyLeaveRequest(credentials) {
        return await axios.post(`${environmentConfig.InternalDashboardHost}/approve-deny-employee-leave`, credentials);
    }
}
