import { createRouter, createWebHistory } from 'vue-router';
import Registration from '../pages/Registration.vue';
import Dashboard from '../pages/Dashboard.vue';
import Leaderboard from '../pages/Leaderboard.vue';
import LoginPage from '../pages/Login.vue';
import ApproveLeave from '../pages/ApproveLeave.vue';
import UserLeaveApplicationStatus from '../pages/UserLeaveApplicationStatus.vue';
import ForgotPassword from '../pages/ForgotPassword.vue';
import OurTeam from '../pages/OurTeam.vue';
import store from '../store';

function routerGuard(to, from, next) {
  let isAuthenticated = false;
  if (store.state.userData !== undefined) {
    isAuthenticated = true;
  } else {
    alert('Access Denied - User not logged in. Rerouting to login page.');
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    next();
  } else {
    next('/');
  }
}

const routes = [
  {
    path: '/register',
    name: 'RegisterPage',
    component: Registration,
    meta: { hideLogoutButton: true },
  },
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage,
    meta: { hideLogoutButton: true },
  },
  {
    path: '/dashboard',
    name: 'DashBoard',
    beforeEnter: routerGuard,
    component: Dashboard,
  },
  {
    path: '/leaderboard',
    name: 'LeaderBoard',
    beforeEnter: routerGuard,
    component: Leaderboard,
  },
  {
    // TODO: Route paths to use hyphenated word breaks not camel case
    path: '/approve-leave',
    name: 'ApproveLeave',
    beforeEnter: routerGuard,
    component: ApproveLeave,
  },
  {
    path: '/user-leave-application-status',
    name: 'UserLeaveApplicationStatus',
    beforeEnter: routerGuard,
    component: UserLeaveApplicationStatus,
  },
  {
    path: '/our-team',
    name: 'OurTeam',
    beforeEnter: routerGuard,
    component: OurTeam,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { hideLogoutButton: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
