import Vuex from 'vuex';
import { createApp } from 'vue';

createApp().use(Vuex);

export default new Vuex.Store({
    plugins: [],
    state: {
        userData: undefined,
        allInternalUsersMsisdnsAndTokens: undefined,
        allEmployeesRequestedLeave: undefined,
    },
    /* eslint-disable no-param-reassign */
    mutations: {
        setUser(state, userData) {
            state.userData = userData;
        },
        setAllUsersMsisdnAndTokens(state, allInternalUsersMsisdnsAndTokens) {
            state.allInternalUsersMsisdnsAndTokens = allInternalUsersMsisdnsAndTokens;
        },
        setAllEmployeesRequestedLeave(state, allEmployeesRequestedLeave) {
            state.allEmployeesRequestedLeave = allEmployeesRequestedLeave;
        },
    },
    /* eslint-enable no-param-reassign */
    modules: {},
});
