<template>
  <div class="d-flex login-container flex-row justify-content-around flex-wrap">
    <div class="d-flex flex-column justify-content-around col-12 col-md-6 col-lg-6 col-xl-4">
      <div class="d-flex flex-column welcome-message p-4">
        <div style="font-size: 25px">Welcome to the</div>
        <div style="font-size: 50px; font-weight: bold" >FANCLASH</div>
        <div style="font-size: 50px; font-weight: bold">INTERNAL</div>
        <div style="font-size: 50px; font-weight: bold">DASHBOARD</div>
      </div>
      <div class="d-flex flex-column login-page-illustration p-4">
        <img id =illustration-image src="../assets/Illustration-08.png" class="illustration-image" >
      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center text-center col-12 col-md-6 col-lg-6 col-xl-4 pb-5">
      <div style="font-size: 25px">Please log into your account</div>
      <input class="m-2" v-model="email" placeholder="email">
      <input class="m-2" v-model="password" placeholder="password" type="password">
      <button class="btn-login m-3" @click="handleLoginSubmit">login</button>
      <div class="d-flex flex-column text-center pt-2" v-if="userDoesNotExist">
        <p style="font-size:20px;color:red ;white-space: pre-wrap">{{ userDoesNotExistValidationMessage }}</p>
      </div>
      <div class="d-flex flex-column text-center pt-2" v-if="passwordMatch">
        <p style="font-size:20px;color:red ;white-space: pre-wrap">{{ passwordValidationMessage }}</p>
      </div>
      <div class="d-flex flex-row justify-content-center flex-wrap pt-5">
        <div style="font-size:20px">Don't have an account?</div>
        <button class="create-account-btn" @click="routeToRegisterPage">create new</button>
      </div>
      <div class="pt-2 pb-2 forgot-password" @click="onPasswordForgetClicked">Forgot Password?</div>
    </div>
    <div class="d-flex flex-column justify-content-end text-center col-12 col-md-6 col-lg-6 col-xl-4">
      <div class="d-flex logo-container flex-row justify-content-evenly">
        <div class="p-2">
          <img src="../assets/Penrose-Gaming-Logo-White-01.png" class="fanclash-logo-img" ></div>
        <div class=" digital-penrose-logo p-2 m-1">
          <img src="../assets/Penrose-Digital-Logo-White-01.png" class="fanclash-logo-img" ></div>
      </div>
      <div class="pt-3 pb-5" style="font-size: 25px">
        Your home of competition and rewards
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import bcrypt from 'bcryptjs';
import NavBar from './NavBar.vue';
import UserAuthenticationService from '../services/userAuthenticationService';

export default {
  name: 'LoginPage',
  components: NavBar,
  computed: {
    ...mapState(['userData', 'leaderboardData']),

  },
  data() {
    return {
      email: '',
      password: '',
      userDoesNotExist: undefined,
      userDoesNotExistValidationMessage: 'No account exists for this email',
      passwordMatch: false,
      passwordValidationMessage: 'Incorrect Password',
      passwordIsValid: '',
    };
  },
  methods: {
    ...mapMutations(['setUser']),
    async handleLoginSubmit() {
      await this.submitLogin();
      this.checkUserExists();
      this.compareEncryptedPassword();
      this.validatePassword();
      await this.routeToDashboard();
    },
    compareEncryptedPassword() {
       this.passwordIsValid = bcrypt.compareSync(this.password, this.userData.Password);
    },
    async submitLogin() {
      const response = await UserAuthenticationService.login({
        email: this.email,
      });
      this.setUser(response.data.UserData);
    },
    onPasswordForgetClicked() {
      this.$router.push({ path: '/forgot-password' });
    },
    async routeToDashboard() {
      if (this.userData.Email === this.email && this.passwordIsValid === true) {
        this.$router.push({ path: '/dashboard' });
      }
    },
    checkUserExists() {
      this.userDoesNotExist = this.userData === undefined;
    },
    validatePassword() {
      this.passwordMatch = this.email === this.userData.Email && this.passwordIsValid === false;
    },
    routeToRegisterPage() {
      this.$router.push('/register');
    },
  },
};
</script>

<style scoped>
.login-container{
  font-family: 'Montserrat', sans-serif;
  background: url("../assets/Background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

.illustration-image {
  width: 400px;
}

.fanclash-logo-img {
  width: 100px;
}

.penrose-logos img {
  width: 100px;
}

input {
  width: 300px;
  height: 50px;
  border-radius: 20px;
  border: 2px solid #F1F1F1;
  padding-left: 15px;
  background-color: #ececec;
  font-size: 20px;
}

.btn-login {
  border-radius: 20px;
  color: white;
  background-color: black;
  width: 300px;
  height: 50px;
  font-size: 22px;
}

.forgot-password {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 5%;
}

.create-account-btn {
  margin-left: 5px;
  border-radius: 10px;
  color: white;
  background-color: black;
  width: 100px;
}

@media screen and (max-width: 790px) {
  #illustration-image {
    display: none;
  }
}
</style>
