<template>
<div class="d-flex flex-row navbar">
    <div class="navbar-fanclash-logo "><img src="../assets/FanClash-Logo-01.png" class="img-fluid"></div>
    <div v-if="!$route.meta.hideLogoutButton" class="logout-button-div"><button @click="onLogoutButtonClicked">Logout</button></div>
</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'NavBar',
  data() {
    return {
      displayLogoutButton: '',
    };
  },
  computed: {
    ...mapState(['userData']),
  },
  methods: {
    ...mapMutations(['setUser']),
    onLogoutButtonClicked() {
      this.clearUserData();
      this.routeToLoginPage();
    },
    routeToLoginPage() {
      this.$router.push('/');
    },
    clearUserData() {
      this.setUser(undefined);
    },
  },
};
</script>
<style scoped>
.navbar{
  position: relative;
  background-color: #f3f3f3;
  height: 65px;
}

.navbar-fanclash-logo{
  position: absolute;
  top: 5%;
  left: 0.5%;
  width: 60px;
}

.logout-button-div{
  position: absolute;
  top: 30%;
  right: 3%;
}

.logout-button-div button{
  width: 80px;
  border-radius: 20px;
  border: 2px solid black;
  color: white;
  background-color: #2c84e8;
}

.user-profile-image img {
  width: 10vw;
}

</style>
