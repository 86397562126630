<template>
  <div class="dashboard-container">
    <LoadingScreen v-if="loadingScreen"></LoadingScreen>
    <div class="d-flex flex-row justify-content-around flex-wrap">
      <div class="d-flex flex-column">
        <div class="">
          <div class="d-flex flex-column welcome-message p-4">
            <div style="font-size: 20px">Welcome to the</div>
            <div style="font-size: 45px; font-weight: bold" >FANCLASH</div>
            <div style="font-size: 45px; font-weight: bold">INTERNAL</div>
            <div style="font-size: 45px; font-weight: bold">DASHBOARD</div>
          </div>
          <div class="d-flex flex-row p-4 pb-5">
            <img :src="profileImage" class="user-profile-image"/>
            <div class="greetings-container">
              <h4 class="">Hello {{ userData.Name }}</h4>
            </div>
          </div>
          <div class="d-flex flex-column pt-5 justify-content-end">
            <div class="d-flex flex-column justify-content-center text-center">
              <div class="d-flex logo-container flex-row justify-content-evenly">
                <div class="p-2"><img src="../assets/Penrose-Gaming-Logo-White-01.png" class="fanclash-logo-img" ></div>
                <div class=" digital-penrose-logo p-2 m-1"><img src="../assets/Penrose-Digital-Logo-White-01.png" class="fanclash-logo-img" ></div>
              </div>
              <div class="pt-3 pb-5 greetings-message" style="font-size: 25px">Your home of competition and rewards</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center text-center pt-4 pb-4">
        <h4>Internal Promotions</h4>
        <p>Vodacom Fantasy R5000 Internal Promotion!</p>
        <button @click="onViewInternalLeaderboardClicked" class="view-internal-leaderboard-btn p-1">View Internal Leaderboard</button>
        <div class="d-flex flex-row justify-content-evenly pt-2">
          <button @click="onHowItWorksBtnClicked" class="how-it-works-btn m-2">How it works</button>
          <div class="d-flex flex-column flex-wrap pt-3">
            <p style="font-size: 14px">Promotion ends: 31-03-2022</p>
          </div>
        </div>
        <div v-if="showHowItWorksPopUp" class="d-flex flex-column popup-banner pt-2">
          <p style="color: #0d85de">The Vodacom Fantasy Internal Promotion pertains only to Fantasy Football. No Predict & Win Points will be allocated to the overall leaderboard.</p>
        </div>
        <div class="d-flex flex-column text-center pt-4">
          <h4>Leave Application</h4>
          <p style="font-size: 14px">Current Annual Leave Days Available: {{userData.LeaveDays}}</p>
          <DatePicker is-range :attributes="attributes" :min-date="new Date()" v-model="range" @dayclick="onDayClick" style="height: 260px ; width: 320px"/>
          <p class="mt-2">Please select the type of leave:</p>
          <div class="d-flex flex-row justify-content-center text-center">
            <label for="annual" class="form-control">
              Annual
              <input type="radio" id="annual" value="Annual" v-model="leaveTypeSelected" />
            </label>
            <label for="sick" class="form-control">
              Sick
              <input type="radio" id="sick" value="Sick" name="radio" v-model="leaveTypeSelected" />
            </label>
          </div>
          <button class="d-flex mt-3 justify-content-center leave-btn" @click="onApplyButtonClicked">Apply</button>
          <div class="d-flex flex-column text-center pt-2"  v-if="leaveApplicationSubmittedPopup">
            <p style="color: red">Leave Application Submitted</p>
          </div>
          <div class="d-flex mt-2 justify-content-center approve-leave" v-if="userAdminApproveLeaveBtn" @click="onApproveLeaveButtonClicked">approve employee leave</div>
          <div v-if="employeeLoggedIn" @click="onViewLeaveApplicationStatusClicked" class="d-flex mt-2 justify-content-center view-leave-status">view leave application status</div>
          <div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-around align-items-center align-content-center pt-2">
          <div class="d-flex flex-column our-team m-1 pb-3">
            <div class="our-team-header">
              <h4>Our Team</h4>
            </div>
            <div class="our-team-img"><img src="../assets/group.png"></div>
            <div class="our-team-view" @click="viewEmployeesClicked"><p>view all our employees</p></div>
          </div>
          <div class="d-flex flex-column our-team-birthdays m-1">
            <div class="our-team-header">
              <h4>Upcoming Birthdays</h4>
            </div>
            <div class="d-flex">
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">name</th>
                  <th scope="col">birthday</th>
                  <th scope="col">countdown</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(staff) in staffUpcomingBirthdaysArray.slice(0,2)" :key="staff.id">
                  <td>{{ staff.name }}</td>
                  <td>{{  staff.birthday }}</td>
                  <td>{{ - staff.countdown }} day(s)</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <img class="illustration-img" src="../assets/Illustration-09.png">
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { DatePicker } from 'v-calendar';
import moment from 'moment';
import LoadingScreen from './LoadingScreen.vue';
import UserAuthenticationService from '../services/userAuthenticationService';
import LeaveRequestService from '../services/leaveRequestService';

export default {
  name: 'DashBoard',
  components: {
    DatePicker,
    LoadingScreen,
  },
  data() {
    return {
      /* eslint-disable */
      loadingScreen: true,
      today: new Date(),
      PromoHeader: 'Vodacom Fantasy R5000 Internal Promotion!',
      PromoText: `It's official. We want to know who plays our game best!`,
      showHowItWorksPopUp: false,
      leaveTypeSelected: undefined,
      days: [],
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: new Date(),
        }
      ],
      range: {
        start: undefined,
        end: undefined,
      },
      employeeLoggedIn: undefined,
      leaveApplicationStatus: undefined,
      leaveApplicationSubmittedPopup: undefined,
      userAdminApproveLeaveBtn: undefined,
      momentLeaveDayDuration : undefined,
      arrayUsers: [],
      staffUpcomingBirthdaysArray: [],
      profileImage: undefined,
    };
  },
  computed: {
    ...mapState(['userData', 'allInternalUsersMsisdnsAndTokens', 'allEmployeesRequestedLeave']),
    dates() {
      return this.days.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    },
  },
  async beforeMount() {
    this.setUser(this.userData);
    const usernameMsisdnAndTokenResponse = await UserAuthenticationService.loginSuccess();
    this.staffDetailsResponse = await UserAuthenticationService.staffDetails();
    this.setAllUsersMsisdnAndTokens(usernameMsisdnAndTokenResponse.data);
    this.toggleAdminOrEmployeeLinks();
    this.setUpcomingBirthdays();
    this.profileImage = `https://${process.env.VUE_APP_AZURE_ACCOUNT}.blob.core.windows.net/${process.env.VUE_APP_CONTAINER_NAME}/${this.userData.Name}_${this.userData.Surname}`;
    this.disableLoadingScreen();
  },
  methods: {
    ...mapMutations(['setUser', 'setAllUsersMsisdnAndTokens', 'setAllEmployeesRequestedLeave']),
    disableLoadingScreen() {
      if (this.userData !== undefined && this.staffUpcomingBirthdaysArray !== undefined && this.profileImage !== undefined) {
        this.loadingScreen = false;
      }
    },
    logoutBtnClicked() {
      this.setUser(null);
      this.$router.push('/');
    },
    viewEmployeesClicked(){
      this.$router.push('/our-team');
    },
    onViewInternalLeaderboardClicked() {
      this.$router.push('/leaderboard');
    },
    toggleAdminOrEmployeeLinks() {
      if (this.userData.Admin === true) {
        this.userAdminApproveLeaveBtn = true
      } else if (this.userData.Admin === null) {
        this.employeeLoggedIn = true
      }
    },
    onHowItWorksBtnClicked() {
      this.showHowItWorksPopUp = !this.showHowItWorksPopUp;
    },
    onDayClick(day){
      const idx = this.days.findIndex(d => d.id === day.id);
      if (idx >=0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          date: day.id,
        });
      }
    },
    durationLeaveCalculation(){
      let firstDate = this.days[0].date;
      const startMoment = moment(firstDate);
      let endDate = this.days.slice(-1)[0].date;
      const endMoment = moment(endDate);
      this.momentLeaveDayDuration = endMoment.diff(startMoment, 'days');
    },
    async onApplyButtonClicked() {
      this.durationLeaveCalculation();
      await this.applyLeaveSubmission();
      await this.sendSMStoAdmin();
      this.leaveSubmissionSubmittedPopUp();
    },
    async applyLeaveSubmission(){
      const leaveSubmissionResponse = await LeaveRequestService.applyLeave({
        surname: this.userData.Surname,
        msisdn: this.userData.Msisdn,
        leaveType: this.leaveTypeSelected,
        startDate: this.days[0].date,
        endDate: this.days.slice(-1)[0].date,
        durationHours: (this.momentLeaveDayDuration + 1) * 24 / 3,
      });
      this.leaveApplicationStatus = leaveSubmissionResponse.data;
    },
    async sendSMStoAdmin(){
      await LeaveRequestService.sendSMS({
        msisdn : process.env.VUE_APP_NUMBER,
        network : "MTN",
        text : `${this.userData.Name} ${this.userData.Surname} has requested ${this.leaveTypeSelected} leave from the ${this.days[0].date} to the ${this.days.slice(-1)[0].date}. Duration ${this.momentLeaveDayDuration + 1} day(s). To approve/deny click https://people.thefanclash.co.za/ `
      })
      await LeaveRequestService.sendSMS({
        msisdn : process.env.VUE_APP_NUMBER_MITCHELL,
        network : "Vodacom",
        text : `${this.userData.Name} ${this.userData.Surname} has requested ${this.leaveTypeSelected} leave from the ${this.days[0].date} to the ${this.days.slice(-1)[0].date}. Duration ${this.momentLeaveDayDuration + 1} day(s). To approve/deny click https://people.thefanclash.co.za/ `
      })
    },
    leaveSubmissionSubmittedPopUp(){
      if (this.leaveApplicationStatus.Application === 'Submission Successful. Pending approval from Admin'){
        this.leaveApplicationSubmittedPopup = true
      }
    },
    setUpcomingBirthdays() {
      this.arrayUsers = this.staffDetailsResponse.data;
      this.arrayUsers.forEach((employeeBirthdayDetails) => {
        let employeeName = employeeBirthdayDetails.FullName;
        let firstName = employeeName.split(" ")[0];
        let massagedBirthday = employeeBirthdayDetails.DateOfBirth.substring(0,10);
        let birthdaySubstring = massagedBirthday.substring(4);
        let year = this.today.getFullYear();
        let updatedBirthday = year + birthdaySubstring
        let a = moment(updatedBirthday).format('M/D/YYYY');
        let b = moment(this.today,'M/D/YYYY');
        let diffDays = b.diff(a, 'days');

        if (new Date(updatedBirthday) > this.today) {
          this.staffUpcomingBirthdaysArray.push({
            name: firstName,
            birthday: moment.utc(updatedBirthday).format('DD MMMM'),
            countdown: diffDays,
          })
        }
      })
      this.sortBirthdaysByMostRecent();
    },
    sortBirthdaysByMostRecent() {
      this.staffUpcomingBirthdaysArray.sort((a,b) => new Date (a.birthday) - new Date(b.birthday));
    },
    async onViewLeaveApplicationStatusClicked() {
      await this.getAllEmployeeRequestedLeave();
      this.$router.push('/user-leave-application-status');
    },
    async onApproveLeaveButtonClicked() {
      await this.getAllEmployeeRequestedLeave();
      this.$router.push('/approve-leave');
    },
    async getAllEmployeeRequestedLeave() {
      const getAllEmployeeRequestedLeaveDatesResponse = await LeaveRequestService.employeeRequestedLeave();
      this.setAllEmployeesRequestedLeave(getAllEmployeeRequestedLeaveDatesResponse.data);
    },
  },
};
</script>

<style scoped>

.dashboard-container {
  font-family: 'Montserrat', sans-serif;
  background: url("../assets/Background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

.greetings-container {
  background-color: black;
  color: white;
  font-size: 16px;
  width: 250px;
  border-radius: 20px;
  height: 40px;
  padding-left: 12px;
  padding-top: 9px;
}
.user-profile-image{
  width:50px;
  height:50px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -5px;
}

.view-internal-leaderboard-btn, .leave-btn {
  width: 320px;
  height: 35px;
  border-radius: 20px;
  border: 2px solid black;
  color: white;
  background-color: #2c84e8;
}

.how-it-works-btn {
  background-color: white;
  border-radius: 20px;
  width: 150px;
  height: 35px;
}

.leave-submitted-popup {
  position: absolute;
  top: 30%;
  z-index: 1;
  border-radius: 6px;
  border: 2px solid black;
  background-color: #F1F1F1;
  padding-top: 1%;
  height: 100px;
  width: 250px;
}

.our-team, .our-team-birthdays {
  position: relative;
  height: 140px;
  width: 250px;
  border-radius: 8px;
  color: black;
}

.our-team-header {
  padding-left: 5%;
  padding-top: 5%;
  border-bottom: 1px solid black;
}

.our-team-img img{
  position: absolute;
  top: 38%;
  left: 6%;
  width: 50px;
}

.our-team-view {
  font-size: 14px;
  padding-left: 10px;
  position: absolute;
  top:75%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 5%;
}

table thead {
  font-size: 10px;
  font-weight: lighter;
  text-align: center;
}

table tbody {
  font-size: 14px;;
  text-align: center;
}

.birthday-calendar-header img {
  position: absolute;
  width: 40px;
  right: 3%;
  top: 5%;
}

.approve-leave{
  bottom: 1%;
  color: black;
  height: 33px;
  font-size: 15px;
  padding-top: 1%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 5%;
}

.view-leave-status{
  bottom: 1%;
  color: black;
  height: 33px;
  font-size: 15px;
  padding-top: 1%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 5%;
}

.leave-calender p {
  padding-left: 3%;
  color: black;
}

.form-control {
  width: 100px;
  border-radius: 10px;
}

.fanclash-logo-img {
  width: 100px;
}

.illustration-img {
  width: 350px;
}

.popup-banner {
  width: 300px;
}
@media screen and (max-width: 1120px) {
  .illustration-img {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .fanclash-logo-img, .greetings-message {
    display: none;
  }
}
</style>
