<template>
<div class="our-team-container">
  <LoadingScreen v-if="loadingScreen"></LoadingScreen>
  <div class="d-flex flex-row justify-content-evenly pt-4 flex-wrap">
    <div class="d-flex align-items-center justify-content-around flex-column bd-highlight left-div mb-3 col-xl-3 col-lg-3 col-md-3">
      <div class="pt-4 bd-highlight">
        <div style="font-size: 20px">The</div>
        <div style="font-size: 30px; font-weight: bold" >FANCLASH TEAM</div>
      </div>
      <div class="mt-auto d-flex logo-container flex-column p-4 bd-highlight">
        <img src="../assets/Illustration-10.png" class="illustration-image" style="width: 350px">
      </div>
    </div>
    <div class="d-flex pt-4 flex-column bd-highlight mb-3 col-xl-6 col-lg-6 col-md-6">
      <h4>Our staff</h4>
      <table class="table">
        <tbody>
        <tr v-for="(staff) in staffTableDisplayedToUser" :key="staff.id">
          <td>{{ staff.name }}</td>
          <td>{{ staff.email }}</td>
          <td>{{ staff.msisdn }}</td>
          <td>{{ staff.dateOfBirth }}</td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex pt-3 pb-5 flex-column align-items-center">
        <button @click="backBtnClicked" class="go-back-button">go back</button>
      </div>
      <div class="d-flex flex-column pt-5 justify-content-end">
        <div class="d-flex flex-column justify-content-center text-center">
          <div class="d-flex logo-container flex-row justify-content-evenly">
            <div class="p-2"><img src="../assets/Penrose-Gaming-Logo-White-01.png" class="fanclash-logo-img" style="width: 100px" ></div>
            <div class=" digital-penrose-logo p-2 m-1"><img src="../assets/Penrose-Digital-Logo-White-01.png" class="fanclash-logo-img" style="width: 100px" ></div>
          </div>
          <div class="pt-3 pb-5 greetings-message" style="font-size: 20px">Your home of competition and rewards</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import LoadingScreen from './LoadingScreen.vue';
import UserAuthenticationService from '../services/userAuthenticationService';

export default {
  name: 'OurTeam',
  components: { LoadingScreen },
  data() {
    return {
      loadingScreen: true,
      employeeDetailsTable: [],
      staffTableDisplayedToUser: [],
    };
  },
  async beforeMount() {
    this.staffDetailsResponse = await UserAuthenticationService.staffDetails();
    this.setEmployeeStaffTable();
    this.massageStaffData();
    this.disableLoadingIcon();
  },
  methods: {
    disableLoadingIcon() {
      if (this.staffTableDisplayedToUser !== undefined) {
        this.loadingScreen = false;
      }
    },
    setEmployeeStaffTable() {
      this.employeeDetailsTable = this.staffDetailsResponse.data;
    },
    massageStaffData() {
      this.employeeDetailsTable.forEach((employee) => {
        const cellphoneNumber = 0 + (employee.Msisdn).slice(2);
        const birthDate = moment(employee.DateOfBirth).utc().format('DD MMMM');

        this.staffTableDisplayedToUser.push({
          name: employee.FullName,
          email: employee.Email,
          msisdn: cellphoneNumber,
          dateOfBirth: birthDate,
        });
      });
    },
    backBtnClicked() {
      this.$router.push('/dashboard');
    },
  },
};
</script>

<style scoped>
.our-team-container {
  font-family: 'Montserrat', sans-serif;
  background: url("../assets/Background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

.go-back-button {
  border: 2px solid black;
  color: black;
  border-radius: 20px;
  background-color: white;
  width: 100px;
}

table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px; /* correct offset on first border spacing if desired */
}
td {
  border: solid 2.5px black;
  border-style: solid none;
  padding: 10px;
}
td:first-child {
border-left-style: solid;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

@media screen and (max-width: 992px) {
  .illustration-image {
    display: none;
  }
}
@media (max-width: 700px) {
  table {
    width: 90vw;
    font-size: 15px;
  }
  table button {
    width: 50px;
  }
}
@media (max-width: 620px) {
  table {
    width: 90vw;
    font-size: 15px;
    display: block;
    overflow: auto;
    white-space: nowrap;
  }
}

</style>
