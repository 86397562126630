import axios from 'axios';
import { environmentConfig } from '../config/environmentConfig';

export default class UserAuthenticationService {
    /* eslint-disable */
    static async register(credentials) {
        return await axios.post(`${environmentConfig.InternalDashboardHost}/register-new-user`, credentials);
    }

    static async login(credentials) {
        return await axios.post(`${environmentConfig.InternalDashboardHost}/login-user`, credentials);
    }

    static async loginSuccess() {
        return await axios.get(`${environmentConfig.InternalDashboardHost}/get-all-users-data`);
    }

    static async forgotPassword(credentials) {
        return await axios.post(`${environmentConfig.InternalDashboardHost}/update-user-password`, credentials);
    }

    static async staffDetails() {
        return await axios.get(`${environmentConfig.InternalDashboardHost}/get-all-staff-details`);
    }
}
